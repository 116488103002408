import { Title } from '@solidjs/meta';
import { Heading } from '@troon/ui';
import { NoHydration } from 'solid-js/web';
import { Content } from '../../../../components/content';

export default function DEIPage() {
	return (
		<NoHydration>
			<Title>Diversity, Equity, and Inclusion | Troon</Title>

			<Content>
				<Heading as="h1">Diversity, Equity, and Inclusion at Troon</Heading>
			</Content>
		</NoHydration>
	);
}
